import './App.css'

export default function App() {

    return (
        <div className='bkgall'>
            <p className='text'>Working on my portfolio. See you soon!</p>
        </div>
    )

}